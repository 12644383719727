<template>
  <div class="p-4 px-lg-5 txt-grey-900 this-wraper">
    <div class="flex flex-column justify-content-center align-items-center otp-wraper">
      <OtpInput ref="otpInput" input-classes="otp-input text-center" separator="&nbsp;" :num-inputs="4"
        :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete">
      </OtpInput>
      <button @click="getOTP(true)" class="btn w-50 fs-18" :disabled="timeout > 0 || loading || sendingOtp">
        <span v-if="sendingOtp">
          Hệ thống đang gửi mã OTP, vui lòng chờ trong giây lát
        </span>
        <span v-else>
          Gửi lại mã sau
          <span v-if="timeout"><span class="fw-500">{{ timeout }} </span>giây</span>
        </span>
        <span class="spinner-border ml-1" role="status" v-if="sendingOtp || loading" style="width: 1rem; height: 1rem">
          <span class="sr-only">Loading...</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import moment from 'moment'
import appUtils from '../../utils/appUtils'
export default {
  name: 'ResultLogin',
  components: { OtpInput },
  data () {
    return {
      verifired: false,
      otp: '',
      timeout: 0,
      interval_timeout: null,
      appUtils,
      sendingOtp: false,
      user_id: null,
      loading: false,
      patient_visit_id: null
    }
  },
  mounted () {
    if (this.interval_timeout) clearInterval(this.interval_timeout)
    if (this.$route.params.id) {
      appUtils.removeLocalToken()
      appUtils.removeLocalUser()
      if (this.$route.name === 'IndicationResultLogin') {
        this.sendOtpIndicationResult()
      }
      if (this.$route.name === 'ConsultationResultLogin') {
        this.sendOtpConsultationResult()
      }
      if (this.$route.name === 'PatientVisitResultLogin') {
        this.sendOtpPatientResult()
      }
    }
  },
  computed: {},
  methods: {
    async sendOtpPatientResult () {
      this.sendingOtp = true
      await this.$rf
        .getRequest('GuestRequest')
        .sendOTPPatientVisit({
          patient_visit_identify: this.$route.params.id
        })
        .then((r) => {
          this.user_id = r.data.user_id
          this.patient_visit_id = r.data.patient_visit_id

          this.setIntervalTimeOut()
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.sendingOtp = false
        })
    },
    async sendOtpIndicationResult () {
      this.sendingOtp = true
      await this.$rf
        .getRequest('GuestRequest')
        .sendOtpIndicationLogin(this.$route.params.id)
        .then((r) => {
          this.user_id = r.data.id
          this.setIntervalTimeOut()
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.sendingOtp = false
        })
    },
    async sendOtpConsultationResult () {
      this.sendingOtp = true
      await this.$rf
        .getRequest('GuestRequest')
        .sendOtpConsultationResult(this.$route.params.id)
        .then((r) => {
          this.user_id = r.data.id
          this.setIntervalTimeOut()
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.sendingOtp = false
        })
    },
    async otpVerify (value) {
      const self = this
      const params = {
        user_id: self.user_id,
        otp: value,
        web: 1
      }
      this.loading = true
      await self.$rf
        .getRequest('GuestRequest')
        .otpVerify(params)
        .then((resp) => {
          appUtils.setLocalToken(resp?.data?.access_token)
          setTimeout(() => {
            if (this.$route.name === 'PatientVisitResultLogin') {
              self.validationPatientVisitIdentify()
            } else {
              self.getUserInfo()
            }
          }, 100)
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
          // self.sendAmplitude('Enter OTP Login To View Indication Result', {
          //   Status: 'Fail',
          //   Otp: value,
          //   Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          // })
        })
    },
    async getUserInfo () {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then(async (resp) => {
          appUtils.setLocalUser(resp.data)
          if (self.$route.name === 'IndicationResultLogin') {
            self.$router
              .push({
                name: 'DetailIndication',
                params: { id: self.$route.params.id }
              })
              .then(() => {
                self.$router.go()
              })
          }
          if (self.$route.name === 'ConsultationResultLogin') {
            self.$router
              .push({
                name: 'ConsultationDetail',
                params: { id: self.$route.params.id }
              })
              .then(() => {
                self.$router.go()
              })
          }
          if (self.$route.name === 'PatientVisitResultLogin') {
            await self.$rf
              .getRequest('AuthRequest')
              .getPatientVisitsById(self.patient_visit_id).then(async (res) => {
                const clinicId = res.data?.clinic_id
                if (clinicId) {
                  await self.$rf.getRequest('AuthRequest').getDetailWorkSpace(clinicId).then((response) => {
                    self.sendAmplitude('View Patient Visit Result', {
                      UserID: resp.data?.id || '',
                      UserName: resp.data?.name || '',
                      Phone: res.data?.username || '',
                      PersonID: res.data?.person?.id || '',
                      PersonName: res.data?.person?.name || '',
                      DateTime: moment(new Date()).format('HH:mm:ss DD-MM-YYYY'),
                      Orginization: `${response.data?.org_id} / ${response.data?.org_name}`,
                      Clinic: `${clinicId} / ${response.data?.name}`,
                      Address: response.data?.address || ''
                    })
                  })
                } else {
                  self.sendAmplitude('View Patient Visit Result', {
                    UserID: resp.data?.id || '',
                    Name: resp.data?.name || '',
                    Phone: res.data?.username || '',
                    PersonID: res.data?.person?.id || '',
                    PersonName: res.data?.person?.name || '',
                    DateTime: moment(new Date()).format('HH:mm DD-MM-YYYY'),
                    Error: 'No found clinic'
                  })
                }
              })

            self.$router
              .push({
                name: 'patient-visit',
                params: { id: self.patient_visit_id }
              })
              .then(() => {
                self.$router.go()
              })
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    setIntervalTimeOut () {
      const self = this
      self.timeout = 60
      clearInterval(self.interval_timeout)
      self.interval_timeout = setInterval(() => {
        if (self.timeout > 0) {
          self.timeout--
        } else {
          clearInterval(self.interval_timeout)
        }
      }, 1000)
    },
    handleOnComplete (value) {
      // console.log('OTP completed: ', value)
      this.otpVerify(value)
    },
    handleOnChange (value) {
      // console.log('OTP changed: ', value)
    },
    handleClearInput () {
      this.$refs.otpInput.clearInput()
    },
    async validationPatientVisitIdentify () {
      try {
        const params = {
          patient_visit_identify: this.$route.params?.id
        }
        const response = await this.$rf
          .getRequest('AuthRequest')
          .validationPatientVisitIdentify(params)
        if (response.status === 200) {
          this.getUserInfo()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.otp-wraper {
  padding-left: 16px;
  padding-right: 16px;
  height: calc(100vh - 300px);
}
</style>
